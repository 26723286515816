import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <section
      *ngIf="cargaGlobal"
      class="flex justify-center items-center w-full h-full backdrop-blur-md backdrop-brightness-75 absolute transition ease-in-out"
      style="z-index: 9999"
    >
      <div
        class="rounded-2xl flex justify-center items-center bg-white gap-4 px-8 py-6"
      >
        <app-loader></app-loader>
        <span class="text-sm">Un momento...</span>
      </div>
    </section>

    <section class="h-full w-full">
      <router-outlet></router-outlet>
    </section>
  `,
})
export class AppComponent implements OnInit {
  cargaGlobal = false;

  constructor() { }

  ngOnInit(): void {
    window.addEventListener('verCargaGlobal', () => (this.cargaGlobal = true));
    window.addEventListener('ocultarCargaGlobal', () => setTimeout(() => (this.cargaGlobal = false), 500));
  }
}
