import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() tamanio: 'sm' | 'md' | 'lg' = 'md';

  constructor() { }

  ngOnInit(): void {
  }

  get tamanioLoader(): string {
    const tamanio = {
      sm: 'h-4 w-4',
      md: 'h-8 w-8',
      lg: 'h-10 w-10'
    }

    return tamanio[this.tamanio];
  }
}
