import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { servicioLocalStorage } from '../shared/services/localStorage.service';
import { AuthenticationService } from '../autenticacion/authentication.service';
import { servicioAlertas } from '../shared/services/alertas.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { servicioEventos } from 'src/app/shared/services/eventos.service';

@Injectable({
  providedIn: 'root',
})
export class BackendInterceptorService {
  constructor(
    private _router: Router,
    private _srvAlertas: servicioAlertas,
    private _srvEventos: servicioEventos,
    private _srvLocalStorage: servicioLocalStorage,
    private _srvAutenticacion: AuthenticationService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;

    request = req.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'x-api-key': this.getApiKey(),
        Authorization: this.getToken(),
      },
    });

    return next.handle(request).pipe(
      catchError(error => {
        window.dispatchEvent(new Event('ocultarCargaGlobal'));
        this._srvEventos.dispatchEvent('ocultarCargandoGlobal');
        console.log(error);

        if (!window.navigator.onLine) {
          this._srvAlertas.alertaErronea('Se ha perdido la conexión a internet');
          return throwError('');
        }
        if (error?.error?.mensaje || error?.error?.erroresFormulario?.length > 0) {
          const mensaje = error?.error?.mensaje ? error?.error?.mensaje : error?.error?.erroresFormulario.toString();
          this._srvAlertas.alertaErronea(mensaje);
        }
        if (error.status === 401 && error.error.mensaje !== 'No se encuentra el usuario.') {
          this._srvAutenticacion.bloquearSesion();
          this._router.navigate(['bloqueo-sesion']);
        } else {
          return throwError('');
        }
      })
    );
  }

  getApiKey(): string {
    return this._srvLocalStorage.get('apiKey') || 'apiKey=fe01ce2a7fbac8fafaed7c982a04e229';
  }

  getToken(): string {
    return this._srvLocalStorage.get('token') || '';
  }
}
