import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { servicioLocalStorage } from './localStorage.service';
import { Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { servicioPacientes } from 'src/app/modules/pacientes/servicio-pacientes/paciente.service';
import { servicioPermisos } from './permisos.service';
export interface DatosSesion {
  rolUsuarioId: string;
  personaUsuarioId: string;
  sucursalUsuarioId: string;
}

@Injectable({
  providedIn: 'root'
})

export class serviciosConstantes {

  ambiente = environment;
  public imagenUsuarioPorDefecto = '/assets/images/icons/user.png';
  paises: any[] = [];

  tipoDocumentos: string[] = [
    'RC - REGISTRO CIVIL',
    'TI - TARJETA DE IDENTIDAD',
    'CC - CÉDULA DE CIUDADANÍA',
    'CE - CÉDULA DE EXTRANJERÍA',
    'PA - PASAPORTE',
    'CD - CARNÉ DIPLOMÁTICO',
    'SC - SALVOCONDUCTO',
    'PE - PERMISO ESPECIAL DE PERMANENCIA',
    'CN - CERTIFICADO DE NACIDO VIVO',
    'AS - ADULTO SIN IDENTIFICACIÓN',
    'MS - MENOR SIN IDENTIFICACIÓN',
    'PT - PERMISO TEMPORAL DE PERMANENCIA'
  ];

  tipoDocumentosJuridicos: string[] = [
    'NI - NUMERO DE IDENTIFICACION - NIT',
    'CC - CÉDULA DE CIUDADANÍA',
    'CE - CÉDULA DE EXTRANJERÍA',
    'CD - CARNÉ DIPLOMÁTICO',
    'PA - PASAPORTE',
    'PE - PERMISO ESPECIAL DE PERMANENCIA'
  ];
  tipoSangres: string[] = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];
  religiones: string[] = ['CATÓLICO/A', 'CRISTIANO/A', 'PROTESTANTE', 'TESTIGO DE JEHOVÁ', 'OTRA RELIGIÓN', 'SIN RELIGIÓN'];
  estadosCiviles: string[] = ['SOLTERO/A', 'COMPROMETIDO/A', 'EN RELACIÓN', 'CASADO/A', 'UNIÓN LIBRE', 'SEPARADO/A', 'DIVORCIADO/A', 'VIUDO/A'];
  razas: string[] = ['SIN PERTENENCIA ÉTNICA (BLANCOS Y MESTIZOS)', 'AFROCOLOMBIANO', 'INDÍGENA', 'RAIZAL', 'PALENQUERO', 'GITANO', 'NS/NR',];
  causasDeAtencion: any[] = [
    { valor: 21, nombre: 'Accidente de trabajo' },
    { valor: 22, nombre: 'Accidente en el hogar' },
    { valor: 23, nombre: 'Accidente de tránsito de origen común' },
    { valor: 24, nombre: 'Accidente de tránsito de origen laboral' },
    { valor: 25, nombre: 'Accidente en el entorno educativo' },
    { valor: 26, nombre: 'Otro tipo de accidente' },
    { valor: 27, nombre: 'Evento catastrófico del orígen natural' },
    { valor: 28, nombre: 'Lesión por agresión' },
    { valor: 29, nombre: 'Lesión auto infligida' },
    { valor: 30, nombre: 'Sospecha de violencia fisica' },
    { valor: 31, nombre: 'Sospecha de violencia psicológica' },
    { valor: 32, nombre: 'Sospecha de violencia sexual' },
    { valor: 33, nombre: 'Sospecha de negligencia y abandono' },
    { valor: 34, nombre: 'IVE relacionado con peligro a la salud o vida de la mujer ' },
    { valor: 35, nombre: 'IVE por malformación congénita incompatible con la vida' },
    { valor: 36, nombre: 'IVE por violencia sexual, incesto o por inseminación artificial o transferencia de ovulo fecundado no consentida' },
    { valor: 37, nombre: 'Evento adverso en salud' },
    { valor: 38, nombre: 'Enfermedad general' },
    { valor: 39, nombre: 'Enfermedad laboral' },
    { valor: 40, nombre: 'Promoción y mantenimiento de la salud - Intervenciones individuales.' },
    { valor: 41, nombre: 'Intervención colectiva' },
    { valor: 42, nombre: 'Atención de población materno perinatal' },
    { valor: 43, nombre: 'Seguridad y Salud en el Trabajo' },
    { valor: 44, nombre: 'Otros eventos Catastróficos' },
    { valor: 45, nombre: 'Accidente de mina antipersonal - MAP' },
    { valor: 46, nombre: 'Accidente de Artefacto Explosivo improvisado — AEI' },
    { valor: 47, nombre: 'Accidente de Munición Sin Explotar - MUSE' },
    { valor: 48, nombre: 'Otra víctima de conflicto armado colombiano' },
    { valor: 49, nombre: 'Riesgo ambiental' },
  ];

  constructor(
    private http: HttpClient,
    private _srvPermisos: servicioPermisos,
    private servicioPacientes: servicioPacientes,
    private servicioLocalStorage: servicioLocalStorage) {
  }

  obtenerTipoDocumentos(): string[] {
    return this.tipoDocumentos;
  }

  obtenerTipoDocumentosJuridicos(): string[] {
    return this.tipoDocumentosJuridicos;
  }

  obtenerTipoSangres(): string[] {
    return this.tipoSangres;
  }

  obtenerReligiones(): string[] {
    return this.religiones;
  }

  obtenerEstadosCiviles(): string[] {
    return this.estadosCiviles;
  }

  obtenerRazas(): string[] {
    return this.razas;
  }

  obtenerCausasDeAtencion(): any[] {
    return this.causasDeAtencion;
  }

  obtenerDepartamentos(): Observable<any> {
    return this.http.get(`${this.ambiente.apiUrl}/poblacion/departamentos`);
  }

  obtenerCiudades(codigoDepartamento: string): Observable<any> {
    return this.http.get(`${this.ambiente.apiUrl}/poblacion/municipios/${codigoDepartamento}`);
  }

  obtenerPrestadores(tipoUsuario = 'medico/empleado'): Observable<any> {
    return this.http.get(`${this.ambiente.apiUrl}/prestadores/${tipoUsuario === 'paciente' ? 'contratoActivo' : 'listar'}`);
  }

  obtenerTipoAsitenciaValor(tipoAsistencia: 'TELE CONSULTA' | 'TELECONSULTA' | 'TELE ASISTENCIA' | 'ATENCIÓN PRESENCIAL' | 'ATENCION PRESENCIAL' | 'ATENCION DOMICILIARIA'): string {
    switch (tipoAsistencia) {
      case 'TELE CONSULTA':
      case 'TELECONSULTA':
        return 'TC';
      case 'TELE ASISTENCIA':
        return 'TA';
      case 'ATENCIÓN PRESENCIAL':
      case 'ATENCION PRESENCIAL':
        return 'AP';
      case 'ATENCION DOMICILIARIA':
        return 'AD';
    }
  }

  get datosUsuarioEnSesion(): DatosSesion {
    const datosSesion: DatosSesion = {
      rolUsuarioId: this._srvPermisos.rolUsuarioId,
      personaUsuarioId: this.servicioLocalStorage.get('personaUsuarioId') || '',
      sucursalUsuarioId: this.servicioLocalStorage.get('sucursalUsuarioId') || '',
    }
    return datosSesion;
  }

  /**
   * Función para obtener toda la información de un paciente mediante el atributo 'pacienteId'.
   * @param pacienteId
   */
  async obtenetInformacionPorPaciente(pacienteId?: string): Promise<any> {
    try {
      const respuesta = await this.servicioPacientes.obtenerInformacionPorPacienteComoPromesa(pacienteId ? pacienteId : this.servicioLocalStorage.get('pacienteId'));
      if (respuesta) return respuesta;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Función para copiar un elemento tipo texto del documento (DOM).
   * @param elemento
   */
  copiarElemento(elemento: string): void {
    let selector = document.createElement('textarea');
    selector.style.position = 'fixed';
    selector.value = elemento;
    document.body.appendChild(selector);
    selector.focus();
    selector.select();
    document.execCommand('copy');
    document.body.removeChild(selector);
  }

  obtenerNumeroAleatorio(numeroMinimo: number, numeroMaximo: number): number {
    const numero: number = Math.floor(Math.random() * (numeroMaximo - numeroMinimo) + numeroMinimo);
    return numero;
  }

  /**
   * Genera una palabra aleatoria entre letras mayusculas y minusculas y numeros
   * @param cantidadCaracteres
   * @returns
   */
  generarPalabraAleatoria(cantidadCaracteres: number): string {
    let palabra = '';
    const mayusculas = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const minusculas = 'abcdefghijklmnopqrstuvwxyz';
    const numeros = '0123456789';
    const caracteres = minusculas + numeros;

    for (let i = 0; i <= cantidadCaracteres; i++) {
      palabra += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }
    return palabra;
  }

  marcarFormularioVisitado(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.marcarFormularioVisitado(control);
      }
    });
  }
  validarFormulario(formGroup: UntypedFormGroup): boolean {
    this.marcarFormularioVisitado(formGroup)
    return formGroup.valid
  }

  /**
   * Retorna el mes y año de la fecha actual
   * @returns
   */
  obtenerFechaActual(): string {
    const fechaActual = new Date();
    const anioActual = fechaActual.getFullYear();
    let mesActual: any = fechaActual.getMonth() + 1;
    if (mesActual < 10) mesActual = '0' + mesActual;

    return `${mesActual},${anioActual}`;
  }

  /**
   * TODO: retornar el dia
   * Obtiene un dato especifico de la fecha actual
   * @param dato
   * @returns
   */
  obtenerDatoFechaActual(dato: 'anio' | 'mes') {
    const fechaHoy = new Date();

    if (dato === 'mes') {
      let month = (fechaHoy.getMonth() + 1).toString();
      return month.length === 1 ? "0" + month : month;
    } else {
      return fechaHoy.getFullYear();
    }
  }

  usarPlantilla(plantilla: string, formulario: UntypedFormGroup, campo: string): void {
    const cuerpo = formulario.get(`${campo}`).value;
    formulario.get(`${campo}`).setValue(cuerpo === '' ? plantilla : cuerpo + '\n\n' + plantilla);
  }

}
