// API PRE APP
const API = 'https://sandbox.softmedic.co:8443/SoftmedicAPI';
const API_KEY = '';

export const environment = {
  apiKey: API_KEY,
  apiUrl: `${API}/api`,
  apiFicheros: `${API}`,
  env: 'pre-app',
  production: true,
  reportesUrlPdf: `${API}/PDFRecurso?`,
};
