import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class servicioLocalStorage {

  constructor() { }

  /**
   * Función para enviar información hacia el localStorage.
   * @param llave 
   * @param informacion 
   */
  set(llave: string, informacion: any): void {
    try {
      localStorage.setItem(llave, JSON.stringify(informacion));
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Función para obtener información desde localStorage.
   * @param llave 
   */
  get(llave: string): any {
    try {
      return JSON.parse(localStorage.getItem(llave));
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Función para eliminar un valor de localStorage.
   * @param llave 
   */
  remove(llave: string): void {
    localStorage.removeItem(llave);
  }

  /**
   * Función para limpiar todos los datos de localStorage
   */
  clear(): void {
    localStorage.clear();
  }
}
