import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class servicioCrypto {

  SECRET_KEY = 'MY_SECRET_KEY';

  constructor() { }

  encriptarInformacion(informacion: string | any): string {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(informacion), this.SECRET_KEY);
    } catch (error) {
      console.log(error);
    }
  }

  desencriptarInformacion(informacionEncriptada: string | any): string {
    try {
      return JSON.parse(CryptoJS.AES.decrypt(informacionEncriptada, this.SECRET_KEY).toString(CryptoJS.enc.Utf8));
    } catch (error) {
      console.log(error);
    }
  }
}
