import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { servicioLocalStorage } from '../shared/services/localStorage.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  ambiente = environment;
  ultimaActividad: string;

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _srvLocalStorage: servicioLocalStorage) { }

  /**
   * Verifica si el token del usuario en sesión está o no activo.
   * @param token
   * @returns
   */
  validarToken(token: string): Observable<boolean> {
    return this._http.get<boolean>(`${this.ambiente.apiUrl}/aplicacion/validarToken/${token}`);
  }

  obtenerInstituciones(): Observable<any> {
    return this._http.get(`${this.ambiente.apiUrl}/aplicacion/instancias`);
  }

  iniciarSesion(usuario: Usuario): Observable<any> {
    return this._http.post<Usuario>(`${this.ambiente.apiUrl}/auth`, usuario);
  }

  cambiarContrasenia(usuario): Observable<any> {
    return this._http.put<any>(`${this.ambiente.apiUrl}/usuarios/cambiarContrasena`, usuario);
  }

  cerrarSesion(): void {
    sessionStorage.clear();
    this._srvLocalStorage.clear();
    this._router.navigate(['']);
  }

  /**
   * Guarda el nombre del usuario en sesión y ruta del último componente donde
   * se encontraba el usuario en localStorage y destruye la sesión.
   */
  bloquearSesion(): void {
    const nombreUsuario = this._srvLocalStorage.get('nombreUsuario');
    const nombreCuentaUsuario = this._srvLocalStorage.get('nombreCuentaUsuario');
    const apiKey = this._srvLocalStorage.get('apiKey');
    this.ultimaActividad = this._router.url;
    this.cerrarSesion();

    this._srvLocalStorage.set('ultimaActividad', this.ultimaActividad);
    this._srvLocalStorage.set('nombreUsuario', nombreUsuario);
    this._srvLocalStorage.set('nombreCuentaUsuario', nombreCuentaUsuario);
    this._srvLocalStorage.set('apiKey', apiKey);
  }
}

export interface Usuario {
  nombreCuentaUsuario: string;
  contrasena: string;
  nombre?: string;
  ips?: string;
}
