import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

/* Componente y enrutamiento principal  */
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';

/* Angular Interceptor */
import { BackendInterceptorService } from './interceptors/interceptor.service';

/* Angular Material Framework CSS */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MaterialModule } from './angular-material.module';

/* Modulos de caracteristicas */

/* Componentes principales */
import { Error404Component } from './error404/error404.component';

/* Servicios */
import { servicioEventos } from './shared/services/eventos.service';
import { LoaderModule } from '@shared/components/loader/loader.module';

@NgModule({
  declarations: [
    Error404Component,
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    LoaderModule,

    AppRoutingModule,
  ],
  providers: [
    servicioEventos,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
