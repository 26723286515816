import { Injectable } from '@angular/core';
import { servicioCrypto } from './crypto.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class servicioPermisos {
  ambiente = environment;

  constructor(
    private _http: HttpClient,
    private _srvCrypto: servicioCrypto,) { }

  guardarPermisosUsuario(permisos: any): void {
    try {
      const { permisosPaciente, permisosMedico, permisosEmpleado } = permisos;
      const PERMISOS = {
        permisosPaciente,
        permisosMedico,
        permisosEmpleado,
      };
      const PERMISOS_ENCRIPTADOS = this._srvCrypto.encriptarInformacion(PERMISOS);
      localStorage.setItem('permisos', PERMISOS_ENCRIPTADOS);
    } catch (error) {
      console.log('Error al procesar permisos de usuario', error);
    }
  }

  obtenerPermisosUsuario(): any {
    try {
      return this._srvCrypto.desencriptarInformacion(localStorage.permisos);
    } catch (error) {
      console.log('Error al obtener permisos de usuario', error);
    }
  }

  get rolUsuarioId(): string {
    return this._srvCrypto.desencriptarInformacion(localStorage.rolUsuarioId);
  }

  obtenerTodosLosMenus(): Observable<any> {
    return this._http.get(`${this.ambiente.apiUrl}/menus/${this.rolUsuarioId}`)
  }

  obtenerPermisosParaAccederHaciaModulos(): Observable<any> {
    return this._http.get(`${this.ambiente.apiUrl}/usuarios/modulos/${this.rolUsuarioId}`);
  }
}
