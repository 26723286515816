import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment'
import { servicioLocalStorage } from '../../../shared/services/localStorage.service';

@Injectable({
  providedIn: 'root'
})
export class servicioPacientes {

  ambiente = environment;

  constructor(
    private http: HttpClient,
    private ls: servicioLocalStorage) { }

  crearPaciente(paciente: any): Observable<any> {
    return this.http.post<any>(`${this.ambiente.apiUrl}/pacientes`, paciente);
  }

  actualizarPaciente(paciente: any): Observable<any> {
    return this.http.put<any>(`${this.ambiente.apiUrl}/pacientes/${paciente?.pacienteId}`, paciente);
  }

  buscarPaciente(filtro: string): Observable<any> {
    return this.http.get<any>(`${this.ambiente.apiUrl}/pacientes/${this.ls.get('sucursalUsuarioId') || ''}/${filtro}`);
  }

  obtenerInformacionPorPaciente(pacienteId: string): Observable<any> {
    return this.http.get<any>(`${this.ambiente.apiUrl}/pacientes/${pacienteId}`);
  }

  obtenerInformacionPorPacienteComoPromesa(pacienteId: string): Promise<any> {
    return this.http.get<any>(`${this.ambiente.apiUrl}/pacientes/${pacienteId}`).toPromise();
  }

  cambiarDocumento(informacionPaciente): Observable<any> {
    return this.http.put<any>(`${this.ambiente.apiUrl}/pacientes/${informacionPaciente?.pacienteId}/cambiarDocumento`, informacionPaciente)
  }

  migrarPaciente(paciente: any): Observable<any> {
    return this.http.put<any>(`${this.ambiente.apiUrl}/pacientes/migrar`, paciente);
  }

  trasladarPaciente(paciente: any): Observable<any> {
    return this.http.put<any>(`${this.ambiente.apiUrl}/pacientes/${paciente.pacienteId}/trasladar`, paciente);
  }

  obtenerAniosProgresoPaciente(pacienteId?: string): Observable<any> {
    return this.http.get<any>(`${this.ambiente.apiUrl}/pacientes/aniosAtendido/${pacienteId}`);
  }

  obtenerProgresoPaciente(pacienteId: string, anio: string): Observable<any> {
    return this.http.get<any>(`${this.ambiente.apiUrl}/estadisticas/pacientes/progreso/${pacienteId}/${anio}`);
  }

  // TODO: CREAR SERVICIO DE FORMATOS
  /**
   * Fem = formato evolucion de metas
   * @param pacienteId
   */
  obtenerFem(pacienteId: string, filtro?: string): Observable<any> {
    return this.http.get<any>(`${this.ambiente.apiUrl}/fem/${pacienteId}/${filtro || 'Evaluado'}`);
  }

  /**
   * Fem = formato evolucion de metas
   * @param pacienteId
   */
  obtenerFemPorMedico(pacienteId: string, especialidadMedicoId?: string): Observable<any> {
    return this.http.get<any>(`${this.ambiente.apiUrl}/fem/paciente/${pacienteId}/especialidad/${especialidadMedicoId}`);
  }

  eliminarFem(payload: any): Observable<any> {
    return this.http.put<any>(`${this.ambiente.apiUrl}/fem/eliminar`, payload);
  }

  eliminarInformeTerapeutico(payload: any): Observable<any> {
    return this.http.put<any>(`${this.ambiente.apiUrl}/informeTerapeutico/eliminar`, payload);
  }
}
