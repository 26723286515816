<section class="flex flex-col items-center justify-between h-screen border-b-4 border-blue-500">
  <label class="invisible"></label>

  <div class="">
    <header>
      <p class="sm-logo">Softmedic</p>
      <div class="text-2xl font-bold mt-10">{{ 'Error 404' | uppercase }}</div>
      <p class="font-light">
        Lo sentimos, la pagina que intentas acceder no existe. <br />
        Verifica la URL o regresa a nuestra
        <a routerLink="" class="underline cursor-pointer font-medium">Página principal.</a>
      </p>
    </header>

    <img src="assets/images/illustrations/404.svg" alt="Medical" width="400" class="m-auto mt-6" />
  </div>

  <div class="flex items-center justify-center w-full text-sm font-medium pb-6">
    by <b class="ml-1">LESOFT</b>
    <div class="flex items-center text-sm border-l-2 ml-2 pl-2">
      <img src="../../../assets/images/partners/ADVAISER.png" alt="G&O" width="40" class="ml-2" />
    </div>
  </div>
</section>
