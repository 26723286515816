import { serviciosConstantes } from './constantes.service';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})

export class servicioAlertas {

  constructor(private srvConstantes: serviciosConstantes) { }

  /**
   * Función para mostrar alertas de tipo información.
   * @param titulo
   * @param descripcion
   */
  alertaInformativa(titulo: string, descripcion: string): void {
    Swal.fire({
      title: titulo,
      html: descripcion,
      icon: 'info',
      showConfirmButton: true
    });
  }

  /**
   * Función para mostrar alertas exitosas cuando se realiza una transacción correctamente.
   */
  alertaExitosa(descripcion?: string): void {
    Swal.fire({
      title: 'Transacción exitosa.',
      html: `<div style="margin-bottom: 8px">${descripcion || 'Listo'}</div>`,
      icon: 'success',
      timer: 1500,
      showConfirmButton: false
    });
  }

  /**
   * Función para mostrar alertas erróneas cuando ocurre una eventualidad no esperada en una transacción.
   * Por defecto esta alerta se cierra si el usuario así lo desea pero se puede configurar para que la misma se oculte automáticamente
   * luego de 1 segundo enviando el parámetro 'ocultarAutomaticamente' en true.
   * @param descripcion
   * @param ocultarAutomaticamente
   */
  alertaErronea(descripcion = 'Error'): void {
    Swal.fire({
      title: 'Transacción erronea.',
      html: descripcion,
      icon: 'error',
    });
  }

  /**
   * Función para mostrar alertas de tipo pregunta para realizar una acción u otra.
   * @param pregunta
   * @param descripcion
   */
  alertaPregunta(pregunta: string, descripcion?: string): Promise<any> {
    return Swal.fire({
      title: pregunta,
      html: descripcion,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false
    });
  }

  /**
   * Función para mostrar alertas de tipo pregunta con confirmacion necesaria para realizar una acción u otra.
   * @param pregunta
   * @param descripcion
   * @param campoConfirmacion
   * @param filtroConfirmacion
   * @returns
   */
  alertaPreguntaConfirmacion(pregunta: string): Promise<any> {
    const palabraAleatoria = this.srvConstantes.generarPalabraAleatoria(5);

    return Swal.fire({
      title: pregunta,
      html: `Para confirmar esta acción por favor escriba <b>${palabraAleatoria}</b>`,
      icon: 'question',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      didOpen: () => {
        Swal.disableButtons();
        Swal.getInput().addEventListener('keyup', (e: any) => {
          e.target.value === palabraAleatoria ? Swal.enableButtons() : Swal.disableButtons();
        })
      }
    });
  }

}
